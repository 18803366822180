@use '../../_shared.scss';

.award {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 2px shared.$base-color;
  margin: 30px shared.$mobile-margin 24px shared.$mobile-margin;
  padding: 10px 12px;
  @media all and (min-width: 601px) {
    padding: 15px 18px;
  }
  @media all and (min-width: 751px) {
    padding: 15px 18px;
  }
  @media all and (min-width: 901px) {
    padding: 32px 36px;
  }
  @media all and (min-width: 1101px) {
    margin: 0 shared.$desktop-margin 24px shared.$desktop-margin;
  }
  @media all and (min-width: 1221px) {
    padding: 32px 36px;
  }
  @media all and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__informations {
    display: flex;
    align-items: center;
    flex-direction: column;
    @media all and (min-width: 601px) {
      flex-direction: row;
      align-items: center;
    }
    &__index {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: flex-start;
      min-width: 48px;
      min-height: 48px;
      margin-right: 24px;
      font-size: 1.5rem;
      background-color: shared.$flash-color;
      color: white;
      @media all and (min-width: 601px) {
        flex-direction: column;
        margin-bottom: 0;
        margin-right: 24px;
      }
    }
    &__content {
      h4 {
        font-size: 1.5rem;
        font-weight: 700;
        margin: 0;
        padding-right: 24px;
        @media all and (max-width: 900px) {
          font-size: 1.125rem;
        }
        @media all and (max-width: 600px) {
          margin: 0;
          padding: 0;
        }
      }
      p {
        font-size: 1.125rem;
        font-weight: 500;
        margin: 0;
        @media all and (max-width: 600px) {
          margin: 0;
        }
      }
    }
  }
  &__date {
    align-self: flex-end;
    @media all and (min-width: 601px) {
      align-self: auto;
    }
  }
}
