@use '../../_shared.scss';

.flash-stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  border: solid 2px shared.$base-color;
  width: 100%;
  margin: 0 shared.$mobile-margin;

  span {
    display: block;
    font-weight: 700;
    text-transform: uppercase;
    &:first-of-type {
      font-size: 3.5rem;
    }
  }

  @media all and (min-width: 601px) {
    padding: 24px 0;
  }

  @media all and (min-width: 1221px) {
    width: 33%;
    margin: 0;
  }
}
