@use './_shared.scss';

.awards-container {
  position: relative;
  overflow: hidden;
  &__code-icon {
    position: absolute;
    top: 50%;
    right: 0%;
    width: 150px;
    transform: rotate(8deg) translate(-20%, -50%);
  }
}

.loader {
  display: flex;
  justify-content: center;
  margin: 35px auto;
}
