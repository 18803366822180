@use '../../_shared.scss';

.stats-text {
  span {
    display: block;
    font-size: 1.5rem;
  }
  &__element {
    color: shared.$flash-color;
    text-transform: uppercase;
    margin-bottom: 10px;
    padding-right: 15px;
    font-weight: 600;
  }
  &__detail {
    font-size: 1.5rem;
    font-weight: 700;
  }
}
