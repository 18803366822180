@use '../../_shared.scss';

.contactForm {
  margin: 0 shared.$mobile-margin 95px shared.$mobile-margin;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  @media all and (min-width: 901px) {
    // margin: 0 shared.$desktop-margin 95px shared.$desktop-margin;
    margin: auto;
  }

  & label {
    margin-bottom: 4px;
    font-weight: bold;
    & span {
      margin-left: 10px;
      color: shared.$flash-color;
    }
  }
  & input,
  textarea {
    margin-bottom: 16px;
    padding: 12px 16px;
    min-height: 48px;
    @include shared.main-font;
    @media all and (max-width: 750px) {
      min-height: 30px;
    }
  }
  & .checkbox-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    & label {
      font-weight: normal;
    }
    & input[type='checkbox'] {
      padding: 20px;
      margin: 0 15px 0 0;
      min-width: 24px;
      min-height: 24px;
    }
  }
  &__submitBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: shared.$flash-color;
    color: white;
    border: none;
    margin: 40px auto 0 auto;
    padding: 14px 32px;
    min-height: 60px;
    min-width: 186px;
    font-size: 1.5rem;
    letter-spacing: 0.08rem;
    font-weight: 600;
    transition: box-shadow 0.3s;
    cursor: pointer;
    &:hover {
      transition: box-shadow 0.3s;
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.5);
    }
  }
}

.standard {
  border: none;
}
.errors {
  border: solid 1px shared.$flash-color;
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.sender {
  margin: 16px auto;
  width: 65px;
  height: 30px;
  position: relative;
}
.sender:before {
  content: '';
  position: absolute;
  border-radius: 50px;
  box-shadow: 0 0 0 3px inset shared.$flash-color;
  animation: l3 0.75s infinite alternate;
}
@keyframes l3 {
  0% {
    inset: 0 35px 0 0;
  }
  50% {
    inset: 0 0 0 0;
  }
  100% {
    inset: 0 0 0 35px;
  }
}
