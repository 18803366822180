@use '../../_shared.scss';

.footer {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 66px;
  font-weight: 500;
  &__information {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 45px;
    & p {
      margin-bottom: 0;
      padding-bottom: 10px;
      & span {
        display: inline-block;
        margin-left: 10px;
      }
    }
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: center;
    & a {
      margin: 0 8px;
      padding: 10px;
    }
  }

  @media all and (min-width: 769px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 66px shared.$desktop-margin;
    &__information {
      width: 50%;
      align-items: flex-start;
      text-align: left;
    }
    &__links {
      width: 50%;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
}
