@use '../../_shared.scss';

.about {
  margin: 30px shared.$mobile-margin 0 shared.$mobile-margin;
  @media all and (min-width: 1101px) {
    margin: 100px shared.$desktop-margin 0 shared.$desktop-margin;
  }
  &__text {
    display: flex;
    flex-direction: column;
    @media all and (min-width: 1001px) {
      flex-direction: row;
      gap: 20px;
    }
    &__title {
      font-size: 1.5rem;
      width: 100%;
      font-weight: 700;
      @media all and (min-width: 1001px) {
        width: 40%;
      }
    }
    &__content {
      width: 100%;
      margin-left: 0;
      font-size: 1.125rem;
      & p {
        line-height: 1.6rem;
      }
      &__title {
        font-size: 2rem;
        margin-top: 0;
      }
      &__tags {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // align-items: center;
        margin-top: 32px;
        gap: 30px;
        @media all and (min-width: 601px) {
          flex-direction: row;
        }
      }
    }
  }
  &__stats {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    @media all and (min-width: 1221px) {
      margin: 100px auto 0 auto;
    }
    @media all and (max-width: 1220px) {
      flex-direction: column;
    }
  }
}
