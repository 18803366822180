@use '../../_shared.scss';

.banner-container {
  display: none;
  @media all and (min-width: 769px) {
    display: flex;
    align-items: center;
    min-height: 175px;
    overflow-x: clip;
  }
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 8vh;
    font-size: 1.125rem;
    font-weight: 700;
    transform-origin: center;
    transform: rotate(-3deg) scaleX(1.01);
    transform-style: preserve-3d;
    &__techno {
      margin: auto 35px;
      text-transform: uppercase;
    }
  }
}
