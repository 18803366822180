@use '../../_shared.scss';

.skills {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
  margin: 30px shared.$mobile-margin;

  @media all and (min-width: 851px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }

  @media all and (min-width: 1101px) {
    margin: 30px shared.$desktop-margin;
  }
}
