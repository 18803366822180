@use '../../_shared.scss';

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  margin: 0 shared.$mobile-margin 0 shared.$mobile-margin;
  padding: 0 calc((100% - 125em) / 2);
  @media all and (min-width: 636px) {
    margin: 0 shared.$desktop-margin 0 shared.$desktop-margin;
  }
  &__logo {
    display: flex;
    align-items: center;
    &__title {
      font-size: 1.5rem;
      margin-left: 5px;
    }
  }
  &__nav {
    display: flex;
    list-style-type: none;
    padding-left: 0;
    display: none;
    @media all and (min-width: 851px) {
      display: flex;
    }
    &__link {
      position: relative;
      padding: 8px 16px;
      text-transform: uppercase;
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        width: 50%;
        height: 1px;
        transform-origin: center;
        transform: translateX(50%) scale(0);
        background-color: shared.$flash-color;
        @include shared.main-transition(transform);
        @include shared.main-animation(transform);
      }
      &:hover::after {
        transform: translateX(50%) scale(01);
      }
    }
  }
}

.svg-container {
  display: flex;
  justify-content: center;
  &__svg {
    &:not(:last-of-type) {
      margin-right: -22px;
    }
    &:last-of-type {
      margin-right: -12px;
    }
    &--left {
      animation: animated 0.5s 2.5s cubic-bezier(0.17, 0.67, 0.83, 0.67)
        forwards;
    }
    &--center {
      animation: animated 0.5s 3s cubic-bezier(0.17, 0.67, 0.83, 0.67) forwards;
    }
    &--right {
      transform: rotateY(180deg);
      animation: animated 0.5s 3.5s cubic-bezier(0.17, 0.67, 0.83, 0.67)
        forwards;
    }
  }
}
.chevron,
.line {
  width: 34px;
  height: 25px;
  stroke-width: 1;
}
.chevron {
  stroke-dasharray: 20;
  stroke-dashoffset: 20;
}
.line {
  stroke-dasharray: 150;
  stroke-dashoffset: 150;
}
.filled:not(:last-of-type) {
  margin-right: -22px;
}
@keyframes animated {
  to {
    stroke-dasharray: 20;
    stroke-dashoffset: 0;
  }
}
@keyframes animated-line {
  to {
    stroke-dasharray: 150;
    stroke-dashoffset: 0;
  }
}
