@use '../../_shared.scss';

.contact-link {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 25px;
  border: solid 1px shared.$base-color;
  width: max-content;
  z-index: 1;
}
