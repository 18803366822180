@use '../../_shared.scss';

.project {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 30px shared.$mobile-margin;
  @media all and (min-width: 1101px) {
    justify-content: space-between;
  }

  &__image {
    width: 100%;
    & a {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      object-fit: cover;
      & img {
        width: 100%;
        max-height: 600px;
        object-fit: cover;
        max-width: 600px;
        @include shared.main-transition(filter);
        &:hover {
          filter: brightness(90%);
        }
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    line-height: 1.6rem;
    width: 100%;
    &__tag {
      font-size: 1.25rem;
      font-weight: 700;
      color: white;
      background-color: shared.$flash-color;
      width: max-content;
      text-transform: uppercase;
      letter-spacing: 0.08rem;
      width: 20px;
      height: 10px;
      margin-top: 0;
      margin-bottom: 32px;
    }
    h4 {
      margin: 0;
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.5rem;
      @media all and (min-width: 1201px) {
        font-size: 2.5rem;
      }
    }
    .link {
      font-size: 1.235rem;
      font-weight: 700;
      color: shared.$flash-color;
      width: max-content;
      @include shared.main-transition(transform);
      &:hover {
        transform: translateX(5px);
      }
    }
  }

  @media all and (min-width: 1101px) {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 shared.$desktop-margin 95px shared.$desktop-margin;
    &__image {
      width: 50%;
      & a {
        justify-content: flex-start;
      }
    }
    &--reversed {
      flex-direction: row-reverse;
      & .project__image a {
        justify-content: flex-end;
      }
    }
  }
}
