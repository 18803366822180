@use '../../_shared.scss';

.add-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin: auto;
  padding: 5px;
  cursor: pointer;
  &__cross {
    width: 30px;
    height: 30px;
    clip-path: polygon(
      0 45%,
      45% 45%,
      45% 0,
      55% 0,
      55% 45%,
      100% 45%,
      100% 55%,
      55% 56%,
      55% 100%,
      45% 100%,
      45% 55%,
      0 55%
    );
    background-color: shared.$flash-color;
  }
}
